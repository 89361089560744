import React from "react"
import * as Icon from "react-feather"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Loan IQ "
const pageTitle = "Loan IQ"
const subTitle = "Banking and Capital Markets"
const para = `<div>
    <h3>Who are we?</h3>
    <p>
    At Atyeti, we specialize in seamless Finastra Loan IQ solution implementations, guiding clients through onboarding, servicing, and go-live stages. Our experienced, dedicated Finastra certified techno-functional resource pool, spread across the UK, US, India, and Singapore, offers comprehensive services, including advisory, customization, training, integration, project management, compliance, testing, and support for the Loan IQ platform.
    </p>
  </div>`
const blockquote = `"Seamless functioning of the entire loan lifecycle."`

const cardItems = [
  {
    id: 1,
    heading: `What do we do?`,
    listItems: (
      <>
        <>
          <p>Our Loan IQ services include:</p>
          <p><strong>Consulting Services - Strategic Assessments</strong></p>
          <li>
            <Icon.Square />
            Assess existing system functionality and its impact on current
            business processes.{" "}
          </li>
          <li>
            <Icon.Square />
            Develop detailed Project Implementation plans, covering
            requirements, workflows, testing, dependencies, and risk mitigation.{" "}
          </li>
          <li>
            <Icon.Square />
            Provide recommendations for best practices and strategic planning.
          </li>
          <p><strong>Implementation, Testing & Reporting Services</strong></p>
          <li>
            <Icon.Square />
            Infrastructure Automation: Leverage Google Cloud Infrastructure for
            seamless Loan IQ installations and upgrades, utilizing Hashicorp’s
            Terraform in a Linux environment.
          </li>
          <li>
            <Icon.Square />
            Customization: Implement custom inbound and outbound interfaces
            using Loan IQ API and SDK, including custom workflows tailored to
            specific client requirements related to Trade Booking, Positions,
            P&L, and other workflows
          </li>
          <li>
            <Icon.Square />
            Automated frameworks based on Agile, BDD/TDD, and Shift-Left
            principles for modernizing testing approaches on Loan IQ systems,
            including API testing, ETL and Data testing, Containerization,
            CI/CD, and in-print automation.
          </li>
          <li>
            <Icon.Square />
            End-to-end testing of the complete loan lifecycle encompassing
            functional, regression and integration testing.
          </li>{" "}
          <li>
            <Icon.Square />
            Expert software implementation, for new Loan IQ installations,
            interfacing with supported applications (Swift, MICR, Teller,
            Lockbox, ACH, Markit, etc.).
          </li>
          <li>
            <Icon.Square />
            Optimize Datanet Reporting: Revise and create reports for
            comprehensive insights aligned with Loan Operation requirements
          </li>
          <p><strong>Migration Services (Cloud Agnostic)</strong></p>
          <li>
            <Icon.Square />
            ETL migration between databases. For ex. DB2 to Oracle or Oracle to
            Postgres EDB Advanced Server.
          </li>
          <li>
            <Icon.Square />
            Converting legacy loan apps to Loan IQ using the DCT tool (Data
            conversion tool) Ex. Flexcube/ACBS to Loan IQ.
          </li>
          <p><strong>Installation & Upgrade Services</strong></p>
          <li>
            <Icon.Square />
            System upgrades - Technical expertise to help upgrade legacy
            systems, including from 7.5 to 7.6 and building, supporting future
            upgrades to version 7.7.
          </li>
          <li>
            <Icon.Square />
            Professionally manage software upgrades, ensuring the system remains
            current with the latest features and security patches. Streamline
            the manual installation process through strategic automation
            techniques for enhanced efficiency.
          </li>
          <p><strong>Training,Support & Maintenance Services</strong></p>
          <li>
            <Icon.Square />
            Comprehensive support for production incidents, including technical
            troubleshooting onsite or remotely, and customized training plans as
            per client requests.
          </li>
          <li>
            <Icon.Square />
            Expertise in application maintenance, management, and support,
            coupled with global delivery models for cost-effective, scalable,
            and efficient services, including data quality improvement through
            Loan IQ automatic controls.
          </li>
          <p><strong>Technology Stack</strong></p>
          <li>
            <Icon.Square />
            Extensive experience with variety of technologies: REST, JMS, SOAP,
            Microservices Java, Node JS, Python, Gen AI, HyperLedger, Mainframes
            and additional skill sets in Cloud, Docker, Kubernetes, Kafka,
            Linux, PostgreSQL.
          </li>
          <li>
            <Icon.Square />
            Extensive experience with variety of technologies: REST, JMS, SOAP,
            Microservices Java, Node JS, Python, Gen AI, HyperLedger, Mainframes
            and additional skill sets in Cloud, Docker, Kubernetes, Kafka,
            Linux, PostgreSQL.
          </li>
        </>
      </>
    ),
  },
]
const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query LoanIqQuery {
    casedetailsJson(slug: { eq: "loan-iq" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
